import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <div>
            <Container.Fullwidth key={key.toString() + 'slider'}>
                <Container.Novel id={key} sliderTitleId="nodelTitle">
                    <div className="bg-grey">
                        <p>1948 Herrenchiemsee</p>
                        <h2>Am Morgen des 1. Sitzungstages des Verfassungskonvents am 10. August 1948 auf Herrenchiemsee ...</h2>
                        <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_1_Bild_1.png" placeholder="none" alt=""/>
                    </div>
                    <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_1_Bild_2.png" placeholder="none" alt=""/>
                    <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_1_Bild_3.png" placeholder="none" alt=""/>
                    <div className="bg-grey">
                        <h2>Eine Unterausschusssitzung im Garten des Schlossgasthofs …</h2>
                        <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_2_Bild_1.png" placeholder="none" alt=""/>
                    </div>
                    <div className="bg-grey">
                        <h2>Bei der letzten allabend-lichen Pressekonferenz am 21. August 1948 …</h2>
                        <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_3_Bild_1.png" placeholder="none"  alt=""/>
                    </div>
                    <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_3_Bild_2.png" placeholder="none" alt=""/>
                    <StaticImage src="../../../../static/images/Herrenchiemsee/Novel/Szene_3_Bild_3.png" placeholder="none" alt=""/>
                </Container.Novel>
            </Container.Fullwidth>
        </div>
    )
}

export default Novel

