import * as React from 'react'
import * as Container from "../../container/container";
import {StaticImage} from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Herrenchiemsee/herrenchiemsee_bild_0006.jpg"}
                           data-glightbox="title: Herrenchiemsee, Augustiner-Chorherrenstift, Luftaufnahme von Südosten; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0006.jpg"
                                         width={335} layout="fixed" placeholder="none"
                                         alt="Herrenchiemsee, Augustiner-Chorherrenstift, Luftaufnahme von Südosten"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                Der Verfassungskonvent tagte im Augustiner-Chorherrenstift, genannt „Altes Schloss“. Im
                                19. Jahrhundert hatte König Ludwig II. von Bayern in dem Gebäudeteil seine Privaträume,
                                bevor er das Neue Schloss Herrenchiemsee beziehen konnte.
                            </p>
                            <p className="copy">Abb. Bayerische Schlösserverwaltung, Bavaria Luftbild</p>
                        </div>
                        <figcaption>
                            Herrenchiemsee, Augustiner-Chorherrenstift, Luftaufnahme von Südosten
                            <span className="description">
                                Der Verfassungskonvent tagte im Augustiner-Chorherrenstift, genannt „Altes Schloss“.
                                Im 19. Jahrhundert hatte König Ludwig II. von Bayern in dem Gebäudeteil seine Privaträume,
                                bevor er das Neue Schloss Herrenchiemsee beziehen konnte.</span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Herrenchiemsee/herrenchiemsee_bild_0007.jpg"}
                           data-glightbox="title: Carlo Schmid im Gespräch mit Hans Nawiasky (rechts) während des Verfassungskonvents, 1948; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0007.jpg"
                                         width={335} layout="fixed" placeholder="none"
                                         alt="Carlo Schmid im Gespräch mit Hans Nawiasky (rechts) während des Verfassungskonvents, 1948"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Neben Anton Pfeiffer zählten Carlo Schmid (SPD, Württemberg-Hohenzollern) und Hermann
                                Louis Brill (SPD, Hessen) zu den prägenden Persönlichkeiten des Konvents. Beide waren in
                                dem Ausschuss, der Schlüsselsätze für das Grundgesetz entwarf. Besonderen Rang hatte der
                                erste Satz des Artikels 1: „Der Staat ist um des Menschen willen da, nicht der Mensch um
                                des Staates willen.“ Der zweite Satz ist ins Grundgesetz übernommen worden: „Die Würde
                                des Menschen ist unantastbar.“
                            </p>
                            <p className="copy">Abb. Süddeutsche Zeitung Photo</p>
                        </div>
                        <figcaption>
                            Carlo Schmid im Gespräch mit Hans Nawiasky (rechts) während des Verfassungskonvents, 1948
                            <span className="description">
                                Neben Anton Pfeiffer zählten Carlo Schmid (SPD, Württemberg-Hohenzollern) und Hermann
                                Louis Brill (SPD, Hessen) zu den prägenden Persönlichkeiten des Konvents. Beide waren in
                                dem Ausschuss, der Schlüsselsätze für das Grundgesetz entwarf. Besonderen Rang hatte der
                                erste Satz des Artikels 1: „Der Staat ist um des Menschen willen da, nicht der Mensch um
                                des Staates willen.“ Der zweite Satz ist ins Grundgesetz übernommen worden: „Die Würde
                                des Menschen ist unantastbar.“</span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <Container.Facsimile
                            pdf="/facsimile/herrenchiemsee/10_07_Bundesgesetzblatt/Bundesgesetzblatt_23_Mai_1949.pdf"
                            img="/images/Herrenchiemsee/herrenchiemsee_bild_0014.jpg"
                            alttext="Veröffentlichung des Grundgesetzes im Bundesgesetzblatt, 23. Mai 1949">
                            <figcaption>
                                Veröffentlichung des Grundgesetzes im Bundesgesetzblatt, 23. Mai 1949
                                <span className="description">
                                        Im Mai 1949 wurde das Grundgesetz von fast allen Landtagen angenommen. Wegen
                                    Bedenken über die Absicherung der föderalen Struktur, lehnte der Bayerische Landtag
                                    das Grundgesetz ab, erkannte aber bei einer Zustimmung von zwei Dritteln der Länder
                                    die Rechtsverbindlichkeit des Grundgesetzes an. Mit der Wiedervereinigung 1990 verlor
                                    das Grundgesetz den in der Präambel von 1949 angesprochenen Übergangscharakter.
                                    </span>
                                <span
                                    className="copy">Abb. Bayerische Staatsbibliothek München, 4 Z 52.95-1949/1950, 1949,
                                    Nr. 1, mit freundlicher Genehmigung des Bundesanzeiger Verlags</span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                    <p>
                        <Container.Facsimile
                            pdf="/facsimile/09_03_Achtung! Bitte nur Seiten mit der Verfassung.pdf"
                            img="/images/Verfassung_neu.png"
                            alttext="Verfassung des Freistaats Bayern"
                            text="Die aktuelle Verfassung des Freistaates Bayern">
                        </Container.Facsimile>
                    </p>
                    <br/>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Herrenchiemsee/herrenchiemsee_bild_0008.jpg"}
                           data-glightbox="title: Der Parlamentarische Rat in Bonn, 1. September 1948; description: .custom-desc1_5; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0008.jpg"
                                         width={335} layout="fixed" placeholder="none"
                                         alt="Der Parlamentarische Rat in Bonn, 1. September 1948"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_5">
                            <p>
                                Sechs der Teilnehmer des Konvents auf Herrenchiemsee befanden sich auch unter den 65 von
                                den Landtagen gewählten Abgeordneten des Parlamentarischen Rates in Bonn. Am 8. Mai 1949
                                nahm der Parlamentarische Rat das Grundgesetz mit 52 zu 12 Stimmen an.
                            </p>
                            <p className="copy">Abb. Bestand Erna Wagner-Hehmke, Haus der Geschichte, Bonn</p>
                        </div>
                        <figcaption>
                            Der Parlamentarische Rat in Bonn, 1. September 1948
                            <span className="description">
                                Sechs der Teilnehmer des Konvents auf Herrenchiemsee befanden sich auch unter den 65 von
                                den Landtagen gewählten Abgeordneten des Parlamentarischen Rates in Bonn. Am 8. Mai 1949
                                nahm der Parlamentarische Rat das Grundgesetz mit 52 zu 12 Stimmen an.</span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
