import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Herrenchiemsee" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1> 1948<br/>Herrenchiemsee<br/><br/></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Grund- und Menschenrechte</li>
                                <li>Verfassung</li>
                                <li>Föderalismus</li>
                            </ul>
                            <p>Auf Einladung der Bayerischen Staatsregierung leistete auf Herrenchiemsee im August 1948
                                ein Sachverständigengremium wichtige Vorarbeiten für das Grundgesetz der Bundesrepublik
                                Deutschland.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Herrenchiemsee/herrenchiemsee_bild_0005.jpg"}
                                   data-glightbox="title: Staatsminister Anton Pfeiffer bei der Eröffnung des Verfassungskonvents auf Herrenchiemsee, 1948; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0005.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Staatsminister Anton Pfeiffer bei der Eröffnung des Verfassungskonvents auf Herrenchiemsee, 1948"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Der Chef der Bayerischen Staatskanzlei Anton Pfeiffer, der schon bei der
                                        Ausarbeitung der Bayerischen Verfassung eine wichtige Rolle gespielt hatte, war
                                        mit der Organisation des Konvents betraut. Der Mitbegründer der CSU zählte zu
                                        den Anhängern des Föderalismus. Am Konvent nahmen neben den elf Ländervertretern
                                        noch deren Mitarbeiter und Sachverständige teil.
                                    </p>
                                    <p className="copy">Abb. Süddeutsche Zeitung Photo</p>
                                </div>
                                <figcaption>
                                    Staatsminister Anton Pfeiffer bei der Eröffnung des Verfassungskonvents auf
                                    Herrenchiemsee, 1948
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Herrenchiemsee/herrenchiemsee_bild_0004.jpg"}
                                   data-glightbox="title: Referentengruppe im Garten des Schlossgasthofs; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Referentengruppe im Garten des Schlossgasthofs"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        Josef Schwalber, Hans Nawiasky, Anton Pfeiffer, Josef Beyerle, Kurt Held (von
                                        links).
                                        Zur Plenarsitzung und bei der förmlichen Abschlusssitzung tagte der Konvent im
                                        ehemaligen Speisezimmer König Ludwigs II. Die Sitzungen der Unterausschüsse und
                                        Besprechungen in Referentengruppen fanden an unterschiedlichen Orten auf der
                                        Insel statt.

                                    </p>
                                    <p className="copy">Abb. Privat</p>
                                </div>
                                <figcaption>
                                    Referentengruppe im Garten des Schlossgasthofs
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
