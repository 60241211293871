import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Das Werk… wird so vielen<br/><span>&nbsp;</span>großen Aufgaben gerecht<br/>werden müssen.“
                    </h2>
                    <div className="inner-container">
                        <p>
                            Auf Herrenchiemsee tagten im August 1948 Vertreter der Länder der westlichen Zonen mit dem
                            Auftrag, einen Verfassungsentwurf auszuarbeiten.
                        </p>
                        <p>
                            Als 1948 die gemeinsame Verwaltung Deutschlands durch die Siegermächte zerbrach, wurde die
                            Gründung eines Weststaats in die Wege geleitet. Im Juli 1948 erhielten die
                            Ministerpräsidenten der Westzonen den Auftrag, eine verfassunggebende Versammlung
                            einzuberufen. Die Ministerpräsidenten setzten einen Ausschuss für die Vorbereitung einer
                            Verfassung ein. Auf Einladung des Bayerischen Ministerpräsidenten Hans Ehard trafen sich die
                            Vertreter der Länder auf Herrenchiemsee. Bayern brachte den „Entwurf eines Grundgesetzes“ in
                            die Beratungen ein.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Auf Herrenchiemsee kamen über 30 Fachleute zusammen. Der Verfassungskonvent führte die
                                Beratungen im Plenum und in drei Unterausschüssen durch, die Grundsatz-, Zuständigkeits-
                                und Organisationsfragen diskutierten. Nach der NS-Barbarei rückte die Unantastbarkeit
                                der Würde des Menschen ins Zentrum.
                            </p>
                            <p>
                                Nach 14 Sitzungstagen lag ein Bericht vor, in dem Probleme und unterschiedliche
                                Standpunkte zusammengefasst waren, vor allem aber ein 149 Artikel umfassender
                                kommentierter Entwurf für ein Grundgesetz. Diese Vorarbeiten bildeten für den
                                Parlamentarischen Rat in Bonn eine wichtige Grundlage für die Beratung des
                                Grundgesetzes. Der Entwurf von Herrenchiemsee beeinflusste dessen Arbeit stark. Am 23.
                                Mai 1949 trat das Grundgesetz in Kraft. Bis heute bestimmt es den Charakter der
                                Bundesrepublik als demokratischer, freiheitlicher und föderal organisierter Rechtsstaat
                                mit starken Ländern.
                            </p>
                        </Container.More>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
