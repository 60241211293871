import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4Parallax = (key, ortName) => {
    return (
        <Container.Part background="bg-img parallax-bg parallax-herrenchiemsee" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Herrenchiemsee/herrenchiemsee_bild_0010.jpg"}
                           data-glightbox="title: Verkaufsstand an einem Schuttberg, München 1948; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0010.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Verkaufsstand an einem Schuttberg, München 1948"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p className="copy">Abb. Haus der Bayerischen Geschichte (Bayerisches Pressebild)</p>
                        </div>
                        <figcaption>
                            Verkaufsstand an einem Schuttberg, München 1948
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Herrenchiemsee/herrenchiemsee_bild_0011.jpg"}
                           data-glightbox="title: Barackenlager in Rosenheim, 1946; description: .custom-desc1_11; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0011.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Barackenlager in Rosenheim, 1946"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_11">
                            <p>
                                Ab 1945 kamen knapp zwei Millionen Flüchtlinge und Vertriebene aus den ehemaligen
                                Ostgebieten in den Westen. Wegen der ohnehin schon gravierenden Wohnungsnot lebten
                                sie oft in Barackenlagern.
                            </p>
                            <p className="copy">Abb. Stadtarchiv Rosenheim, DB_718</p>
                        </div>
                        <figcaption>
                            Barackenlager in Rosenheim, 1946
                            <span className="description">
                                    Ab 1945 kamen knapp zwei Millionen Flüchtlinge und Vertriebene aus den ehemaligen
                                    Ostgebieten in den Westen. Wegen der ohnehin schon gravierenden Wohnungsnot lebten
                                    sie oft in Barackenlagern.</span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
            <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Herrenchiemsee/herrenchiemsee_bild_0009.jpg"}
                               data-glightbox="title: Hungerdemonstration von Studenten in München, 1948; description: .custom-desc1_8; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0009.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Hungerdemonstration von Studenten in München, 1948"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_8">
                                <p>
                                    Die Nahrungsmittelversorgung war bis 1948 problematisch. Die Lebensmittel wurden
                                    rationiert und die Verteilung überwacht.
                                </p>
                                <p className="copy">Abb. Haus der Bayerischen Geschichte (Bayerisches Pressebild)</p>
                            </div>
                            <figcaption>
                                Hungerdemonstration von Studenten in München, 1948
                                <span className="description">
                                Die Nahrungsmittelversorgung war bis 1948 problematisch. Die Lebensmittel wurden
                                rationiert und die Verteilung überwacht.</span>
                            </figcaption>
                        </figure>
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Herrenchiemsee/herrenchiemsee_bild_0012.jpg"}
                               data-glightbox="title: Kinder-Suchdienst der Zonen-Zentrale, München 1949; description: .custom-desc1_9; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0012.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Kinder-Suchdienst der Zonen-Zentrale, München 1949"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_9">
                                <p>
                                    Zwischen 14 und 17 Millionen Menschen in ganz Deutschland suchten in den ersten
                                    Nachkriegsjahren ihre Angehörigen. Suchdiensthelfer nahmen Suchanfragen auf,
                                    forschten nach Vermissten und bemühten sich, getrennte Familien zusammenzuführen.
                                </p>
                                <p className="copy">Abb. Haus der Bayerischen Geschichte (Bayerisches Pressebild)</p>
                            </div>
                            <figcaption>
                                Kinder-Suchdienst der Zonen-Zentrale, München 1949
                                <span className="description">
                                    Zwischen 14 und 17 Millionen Menschen in ganz Deutschland suchten in den ersten
                                    Nachkriegsjahren ihre Angehörigen. Suchdiensthelfer nahmen Suchanfragen auf,
                                    forschten nach Vermissten und bemühten sich, getrennte Familien zusammenzuführen.</span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Herrenchiemsee/herrenchiemsee_bild_0013.jpg"}
                               data-glightbox="title: Zerstörtes Wohnhaus, München 1946; description: .custom-desc1_10; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Herrenchiemsee/herrenchiemsee_bild_0013.jpg" width={335} layout="fixed" placeholder="none"
                                     alt="Zerstörtes Wohnhaus, München 1946"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_10">
                                <p>
                                    Vielfach lebten die Menschen in katastrophalen Wohnverhältnissen. Für die
                                    Wiederherstellung oder den Neubau von Wohnungen fehlte es oft auch an Baustoffen.
                                </p>
                                <p className="copy">Abb. Haus der Bayerischen Geschichte (Bayerisches Pressebild)</p>
                            </div>
                            <figcaption>
                                Zerstörtes Wohnhaus, München 1946
                                <span className="description">
                                    Vielfach lebten die Menschen in katastrophalen Wohnverhältnissen. Für die
                                    Wiederherstellung oder den Neubau von Wohnungen fehlte es oft auch an Baustoffen.</span>
                            </figcaption>
                        </figure>
                        <br/>
                    </div>
                </Container.Row>
            </Container.More>
        </Container.Part>
    )
}

export default TextBlock4Parallax
