import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock3 = (key) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">

                        <h2>Bayern in der Nachkriegszeit</h2>
                        <p>
                            In der Bevölkerung waren in den Jahren 1945–1949 die Alltagssorgen oft drängender als die
                            Frage nach der politischen Zukunft des Landes. Städte lagen in Trümmern, Transportwege waren
                            zerstört und man sorgte sich um Vermisste oder Kriegsgefangene. Den mühsamen Alltag
                            erschwerte die schwierige Versorgungslage. Mit der Bizone, der Trizone und der
                            Währungsreform wurde ab 1947 nach und nach ein zonen- und länderübergreifender
                            Wirtschaftsraum geschaffen, der zur Grundlage für den bundesdeutschen West-Staat wurde.
                        </p>

                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3
