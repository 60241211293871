import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock5White = (key) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox karte">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Herrenchiemsee/Besatzungszonen-Deutschland-1945-1949.png"}
                           data-glightbox="title: Die Besatzungszonen in Deutschland, 1945–1949; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Herrenchiemsee/Besatzungszonen-Deutschland-1945-1949.png" width={335} layout="fixed" placeholder="none" alt="Die Besatzungszonen in Deutschland, 1945–1949"/>
                        </a>
                        <figcaption>
                            Die Besatzungszonen in Deutschland, 1945–1949
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="center">
                        <StaticImage src="../../../../static/images/Herrenchiemsee/silhouette-menschen mit bollerwagen.png" width={335} layout="fixed" placeholder="none" alt=""/>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock5White
